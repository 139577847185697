import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import SEO from '../components/seo'
import Post from '../components/post'
import Layout from '../components/layout'

export default function Template({data}) {
  const post = data.markdownRemark
  const site = data.site.siteMetadata

  return (
    <Layout>
      {/* <Helmet>
        <meta name="description" content={post.excerpt} />
        <meta name="keywords" content={site.keywords} />
        <meta property="og:title" content={post.title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={site.description} />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content= {`${site.siteUrl}/${post.path}`} />
        <link rel="canonical" href={`${site.siteUrl}/${post.path}`} />
      </Helmet> */}
      <SEO title={post.frontmatter.title} description={post.excerpt} image={post.frontmatter.thumb.publicURL}/> 
      <div id="post">
        <Link to="/">Go Back</Link>
        <Post
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        path={post.frontmatter.path}
        html={post.html}
        />
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostByPath ($path: String!){
    markdownRemark(
      frontmatter: {path: {eq: $path}}
    ) {
      html
      frontmatter{
        path
        date (formatString: "MMM Do YYYY")
        title
        thumb{
          publicURL
          childImageSharp{
            fluid(maxWidth: 800, maxHeight: 500){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      excerpt(pruneLength: 280)
    }
    site {
      siteMetadata {
        title
        description
        keywords
        siteUrl
      }
    }
  }
`
